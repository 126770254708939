"use client";
import cloudinaryLoader, { cloudinarySVGLoader } from "@/lib/setup/cloudinaryLoader";
import { PlaceholderValue } from "next/dist/shared/lib/get-img-props";
import Image, { ImageLoader } from "next/image";


type Props = {
    src: string,
    alt: string,
    className?: string,
    fill?: boolean,
    width?: number,
    height?: number,
    loader?: ImageLoader,
    sizes?: string,
    priority?: boolean;
    hideOnError?: boolean;
    customOnError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    svg?: boolean;
    placeholder?: string;
}

type SizeOptions = {
    fill?: boolean;
    width?: number;
    height?: number;
    sizes?: string;
    placeholder?: PlaceholderValue;
}

const CloudImage = ({ src, alt, className = "", fill = true, height = 0, width = 0, loader = undefined, sizes, priority, hideOnError = false, customOnError, svg = false, placeholder = undefined }: Props) => {

    const size: SizeOptions = {};
    if (height && width) {
        size.height = height;
        size.width = width;
    } else {
        size.fill = fill;
    }

    if (sizes) {
        size.sizes = sizes;
    }

    if (placeholder) {
        size.placeholder = placeholder as PlaceholderValue;
    }

    const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (hideOnError) (e.target as HTMLImageElement).style.visibility = "hidden";
        if (customOnError) customOnError(e);
    };

    if (!loader) {
        loader = cloudinaryLoader;
    }
    if (svg) {
        loader = cloudinarySVGLoader;
    }

    return (
        <Image
            loader={loader}
            alt={alt}
            {...size}
            className={className}
            priority={priority}
            src={src}
            onError={onError}
        />
    );
};

export default CloudImage;
