import { CLOUDINARY_TEAMS } from "../constants/cloudinary";
import { escapeText } from "entities";

type LoaderProps = {
    src: string;
    width: number;
    quality?: number;
    svg?: boolean;
}

export default function cloudinaryLoader({ src, width, quality, svg = false }: LoaderProps) {
    let params = ["f_auto", "c_limit", `w_${width}`, `q_${quality ?? "auto"}`];
    let cloudinaryFunction = "image/upload";
    if (svg) {
        params = ["f_svg", "fl_sanitize"];
    }
    // console.log("using cloudinaryLoader for image 1", src);
    if (src.startsWith("https://")) {
        // console.log("using cloudinary fetch function for image 1", src);
        cloudinaryFunction = "image/fetch";
        src =  escapeText(src);
        // console.log("after ", src);
    }
    return `https://res.cloudinary.com/${CLOUDINARY_TEAMS.new}/${cloudinaryFunction}/${params.join(",")}/${src}`;
}

export function cloudinarySVGLoader(props: LoaderProps) {
    return cloudinaryLoader({ ...props, svg: true });
}

export function cloudinaryCoyotivLoader({ src, width, quality }: LoaderProps) {
    const params = ["f_auto", "c_limit", `w_${width}`, `q_${quality ?? "auto"}`];
    let cloudinaryFunction = "image/upload";
    // console.log("using cloudinaryCoyotivLoader for image", src);
    if (src.includes("https://")) {
        src = src.replace("myroom24_old/rooms","");
       // console.log("using cloudinary fetch function for image 2", src);
        cloudinaryFunction = "image/fetch";
        src =  encodeURIComponent(src);
        // console.log("after ", src);
    }
    return `https://res.cloudinary.com/${CLOUDINARY_TEAMS.old}/${cloudinaryFunction}/${params.join(",")}/${src}`;
}

export function simpleUrlLoader({ src }: LoaderProps) {
    return src;
}
